window.addEventListener("load", function (event) {
  let utils = window.fizzyUIUtils;

  let carousel = document.querySelector(".carousel");
  let flkty = new Flickity(carousel, {
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false,
    contain: true,
    setGallerySize: true,
    autoPlay: false,
    lazyLoad: true,
  });

  let cellsButtonGroup = document.querySelector(".carouselNav");
  let cellsButtons = utils.makeArray(cellsButtonGroup.children);

  flkty.on("select", function () {
    let previousSelectedButton = cellsButtonGroup.querySelector(".is-selected");
    let selectedButton = cellsButtonGroup.children[flkty.selectedIndex];
    previousSelectedButton.classList.remove("is-selected");
    previousSelectedButton.classList.add("hideNavItemMoblile");
    selectedButton.classList.add("is-selected");
    selectedButton.classList.remove("hideNavItemMoblile");
  });

  cellsButtonGroup.addEventListener("click", function (event) {
    if (!matchesSelector(event.target, ".button")) {
      return;
    }
    let index = cellsButtons.indexOf(event.target);
    flkty.select(index);
  });
});
