import "alpinejs";

document.documentElement.classList.remove("no-js");

// Modules
import { lazyLoadModule } from "./modules/lazyLoad";

//Components
import "./components/slider";
import "./components/catchment";
import "./components/patientReviews";
import "./components/servicesCarousel";
import "./components/serviceModals";
import "./components/forms";

// Exports

// exampleModule();
lazyLoadModule();
// Global Exports
// window.Cookies = Cookies
