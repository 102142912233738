window.addEventListener("load", function (event) {
  var modalCatch = document.getElementById("catchmentModal");

  var btnCatch = document.getElementById("catchment");

  var span = document.getElementsByClassName("closeModal")[0];

  if (typeof btnCatch === "undefined" || btnCatch === null) {
  } else {
    btnCatch.onclick = function () {
      modalCatch.style.display = "block";
    };
  }

  span.onclick = function () {
    modalCatch.style.display = "none";
  };

  window.onclick = function (event) {
    if (event.target == modalCatch) {
      modalCatch.style.display = "none";
    }
  };
});
