window.addEventListener("load", function (event) {
  let reviewCarousel = document.querySelector(".review-carousel");
  let reviewFlck = new Flickity(reviewCarousel, {
    lazyLoad: true,
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false,
    autoPlay: false,
    cellAlign: "left",
  });
  var previousButton = document.querySelector(".button--previous");
  previousButton.addEventListener("click", function () {
    reviewFlck.previous();
  });
  var nextButton = document.querySelector(".button--next");
  nextButton.addEventListener("click", function () {
    reviewFlck.next();
  });

  reviewFlck.on("select", function () {
    var target = reviewFlck.selectedCell.target;
    var LBtn = document.getElementById("lb");
    var RBtn = document.getElementById("rb");
    if (target == reviewFlck.cells[0].target) {
      LBtn.classList.add("disable-icon");
    } else {
      LBtn.classList.remove("disable-icon");
    }

    if (target == reviewFlck.getLastCell().target) {
      RBtn.classList.add("disable-icon");
    } else {
      RBtn.classList.remove("disable-icon");
    }
  });
});
