window.addEventListener("load", function (event) {
  let servicesCarousel = document.querySelector(".services-carousel");
  let servicesFlck = new Flickity(servicesCarousel, {
    lazyLoad: true,
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false,
    autoPlay: false,
    cellAlign: "left",
  });
  var previousButton = document.querySelector(".button--previous");
  previousButton.addEventListener("click", function () {
    servicesFlck.previous();
  });
  var nextButton = document.querySelector(".button--next");
  nextButton.addEventListener("click", function () {
    servicesFlck.next();
  });

  servicesFlck.on("select", function () {
    var target = servicesFlck.selectedCell.target;
    var LBtn = document.getElementById("LBtn");
    var RBtn = document.getElementById("RBtn");
    if (target == servicesFlck.cells[0].target) {
      LBtn.classList.add("disable-icon");
    } else {
      LBtn.classList.remove("disable-icon");
    }

    if (target == servicesFlck.getLastCell().target) {
      RBtn.classList.add("disable-icon");
    } else {
      RBtn.classList.remove("disable-icon");
    }
  });
});
