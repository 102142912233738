window.addEventListener("load", function (event) {
  var btnService = document.querySelectorAll(".modal-button");

  var modals = document.querySelectorAll(".modal");

  var spans = document.getElementsByClassName("close");

  for (var i = 0; i < btnService.length; i++) {
    btnService[i].onclick = function (e) {
      e.preventDefault();
      modal = document.querySelector(e.target.getAttribute("href"));
      modal.style.display = "block";
    };
  }

  for (var i = 0; i < spans.length; i++) {
    spans[i].onclick = function () {
      for (var index in modals) {
        if (typeof modals[index].style !== "undefined")
          modals[index].style.display = "none";
      }
    };
  }

  window.onclick = function (event) {
    if (event.target.classList.contains("modal")) {
      for (var index in modals) {
        if (typeof modals[index].style !== "undefined")
          modals[index].style.display = "none";
      }
    }
  };
});
$(function () {
  $("#tabs").tabs();
});

$(".sicknote-yes").click(function () {
  $("#div1").hide();
  $("#div2").show();
});

$(".sicknote-yes-yes").click(function () {
  $("#div2").hide();
  $("#div3").show();
});

$(".sicknote-no").click(function () {
  $("#div2").hide();
  $("#div4").show();
});

$(".sicknote-no-yes").click(function () {
  $("#div4").hide();
  $("#div5").show();
});

$(".sicknote-no-no").click(function () {
  $("#div4").hide();
  $("#div6").show();
});

$(".letter").click(function () {
  $("#div1").hide();
  $("#div7").show();
});

$(".sicknotemodal").click(function () {
  $("#div1").show();
  $(".sicknotestep").hide();
});
